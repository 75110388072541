@layer base {
  html {
    color: rgba(0, 0, 0, 0.6);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin: 0;
  }

  @supports (-webkit-touch-callout: none) {
    body {
      /* The hack for Safari */
      height: -webkit-fill-available;
    }
  }

  #root {
    width: 100%;
    height: 100%;
  }

  * {
    font-family: Verdana, Helvetica, sans-serif !important;
  }
}

/* Solution to make striped table */
.ant-table-tbody > tr:nth-child(odd) {
  background-color: rgb(224, 224, 224);
}

.ant-table-tbody > tr:nth-child(even) {
  background-color: rgb(208, 208, 208);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgba(0, 0, 0, 0.2) !important;
}

.ant-table-content thead.ant-table-thead > tr > .ant-table-cell {
  background-color: rgba(26, 70, 131, 1);
  color: rgba(255, 255, 255, 1)
}

thead.ant-table-thead > tr > .ant-table-cell:hover {
  background-color: rgba(32, 86, 162, 1) !important;
}

span.ant-table-column-sorter-inner {
  color: rgba(255, 255, 255, 0.8) !important;
}

td.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.ant-modal-content {
  padding: 10px 14px !important;
}